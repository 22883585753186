"use client";

import { signIn } from "next-auth/react";
import { useSearchParams } from "next/navigation";
import { useEffect } from "react";

const LoginPage = () => {
  const params = useSearchParams();
  const callbackUrl = params.get("callbackUrl");
  const error = params.get("error");

  useEffect(() => {
    if (error === "InvalidAccessToken") {
      signIn("phg", {
        callbackUrl: callbackUrl ?? "/home",
        redirect: true,
      });
    }
    if (error === "NoScopeAccessToken") {
      signIn("phg", {
        callbackUrl: callbackUrl ?? "/home",
        redirect: true,
      });
    }
    signIn("phg", {
      callbackUrl: callbackUrl ?? "/home",
      redirect: true,
    });
  }, [callbackUrl, error]);

  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center space-y-2">
      {error === "InvalidAccessToken" && (
        <>
          <p className="text-slate-500">Authenticating...</p>
        </>
      )}
      {error === "NoScopeAccessToken" && (
        <>
          <p className="text-slate-500">Authenticating...</p>
        </>
      )}
    </div>
  );
};

export default LoginPage;
